import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '../Views/Home';
const Routers = () => {
    return (
        <Switch>
            <Route path="/" exact component={Home}></Route>
        </Switch>
    );
}

export default Routers;
