import React from 'react';

import {
    Container,
    Logo
} from './style';
const Home = () => {
    return (
       <Container>
           <Logo />
       </Container>
    );
}
export default Home;