import React from 'react';
import { Router } from 'react-router-dom';
import Route from './Router';
import history from './Services/history';
import './App.css';

function App() {
  return (
    <Router history={history}>
      <Route />
    </Router>
  );
}

export default App;
