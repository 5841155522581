import styled from 'styled-components';
import logo from './logo1.png';
export const Container = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
`;
export const Logo = styled.img.attrs({
    src: logo
})``;